import { Flex, Grid, useColorModeValue, useColorMode } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import config from 'configs/app';
import blockIcon from 'icons/block.svg';
import clockIcon from 'icons/clock-light.svg';
import cycleWhiteIcon from 'icons/cycle-white.svg';
import cycleIcon from 'icons/cycle.svg';
import clockWhiteValidators from 'icons/validators-white.svg';
import clockValidators from 'icons/validators.svg';
import { getTotalStaked, getActiveValidators, getCurrentCycleBlocks, getCycleEnd } from 'lib/consensus';
import {
  secondsToDhms,
  calcCycleLength,
  calcCycleEndPercent } from 'lib/fuse_utils';

import StatsItem from './StatsItem';

const consensusaddress = config.chain.consensusAddress;

const hasGasTracker = config.UI.homepage.showGasTracker;
const hasAvgBlockTime = config.UI.homepage.showAvgBlockTime;

const Stats2 = () => {
  if (consensusaddress != false) {
    const [ totalStaked, setTotalStaked ] = useState(0);
    const [ totalvalidator, setTotalValidator ] = useState(0);
    const [ currentCycleEndBlock, setCurrentCycleEndBlocks ] = useState(0);
    const [ currentCycleStartBlocks, setCurrentCycleStaerBlocks ] = useState(0);
    const [ cycleEnd, setCycleEnd ] = useState('');
    const [ cyclePercent, setCyclePercent ] = useState(0);
    const { colorMode } = useColorMode();
    const fetchCycle = useCallback(async function() {
      try {
        const [ cycleStartBlock, cycleEndBlock ] = await getCurrentCycleBlocks();
        setCurrentCycleEndBlocks(cycleEndBlock);
        setCurrentCycleStaerBlocks(cycleStartBlock);
  
        const cycleLength = calcCycleLength(cycleStartBlock, cycleEndBlock);
        const cycle_End = await getCycleEnd();
        const cycle_percent = calcCycleEndPercent(cycle_End, cycleLength).toFixed(2);
        if (cycle_percent != '') {
          setCyclePercent(Number(cycle_percent));
        }
  
        const total_staked = await getTotalStaked();
        if (total_staked > 0) {
          setTotalStaked(total_staked);
        }
  
        const total_validator = await getActiveValidators();
        if (total_validator > 0) {
          setTotalValidator(total_validator);
        }
      } catch (err) {
        console.error('fetchCycle', err);
      }
    }, []);
  
    const handleIntervalChange = useCallback(async function() {
      try {
        let time: number;
        const [ cycleStartBlock, cycleEndBlock ] = await getCurrentCycleBlocks();
        let cycleEndInSeconds = await getCycleEnd();
        const cycleLength = calcCycleLength(cycleStartBlock, cycleEndBlock);
        function updateCycleTime() {
          if (!time) {
            time = cycleEndInSeconds;
            appendTime(time);
          } else {
            if (cycleEndInSeconds > 0) {
              time = --cycleEndInSeconds;
              appendTime(time);
            } else {
              // when cycle is done, begin cycle from beginning
              cycleEndInSeconds = cycleLength;
              time = cycleEndInSeconds;
              appendTime(time);
            }
          }
        }
  
        setInterval(updateCycleTime, 1000);
      } catch (err) {
        console.error('handleIntervalChange', err);
      }
    }, []);
  
    function appendTime(time: number) {
      setCycleEnd(secondsToDhms(time));
    }
  
    useEffect(() => {
      fetchCycle();
      handleIntervalChange();
    }, [ fetchCycle ]);
  
    let content;
  
    const lastItemTouchStyle = { gridColumn: { base: 'span 2', lg: 'unset' } };
  
    let itemsCount = 5;
    !hasGasTracker && itemsCount--;
    !hasAvgBlockTime && itemsCount--;
  
    const isOdd = Boolean(itemsCount % 2);
    const bgColor = useColorModeValue('blue.50', 'blue.800');
    const symbol = config.chain.currency.symbol;
    content = (
      <>
        <StatsItem
          icon={ clockIcon }
          title="Total Staked"
          value={ `${ (totalStaked).toLocaleString() } ${ symbol }` }
        />
        <StatsItem
          icon={ colorMode === 'light' ? clockValidators : clockWhiteValidators }
          title="Active Validators"
          value={ `${ String(totalvalidator) } validators` }
          url="https://status.nordekscan.com/"
        />
        <StatsItem
          icon={ colorMode === 'light' ? cycleIcon : cycleWhiteIcon }
          title="Next Cycle"
          value={ cycleEnd }
        />
        <StatsItem
          icon={ blockIcon }
          title="Curent cycle blocks"
          value={ `${ (currentCycleStartBlocks).toLocaleString() } - ${ (currentCycleEndBlock).toLocaleString() }` }
          _last={ isOdd ? lastItemTouchStyle : undefined }
        />
        <Flex
          backgroundColor={ bgColor }
          padding={ 3 }
          borderRadius="md"
          flexDirection="row"
          alignItems="center"
          columnGap={ 3 }
          rowGap={ 2 }
          position="relative"
        >
          <div style={{ width: 86, height: 86, margin: '0 auto' }}>
            <CircularProgressbar
              value={ cyclePercent }
              text={ `${ (cyclePercent * 100).toFixed() }%` }
              maxValue={ 1 }
              styles={ buildStyles({
                rotation: 0,
                strokeLinecap: 'round',
                textSize: '16px',
                pathTransitionDuration: 0.5,
                pathColor: `rgba(62, 152, 199, 100)`,
                textColor: useColorModeValue('#1A202C', 'white'),
                trailColor: '#d6d6d6',
                backgroundColor: '#3e98c7',
              }) }
            />
          </div>
        </Flex>
      </>
    );
    return (
      <Grid
        gridTemplateColumns={{ lg: `repeat(${ itemsCount }, 1fr)`, base: '1fr 1fr' }}
        gridTemplateRows={{ lg: 'none', base: undefined }}
        gridGap="10px"
        marginTop="24px"
      >
        { content }
      </Grid>
  
    );
  } else return null
};

export default Stats2;
